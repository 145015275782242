define("@ember-decorators/argument/-private/initializers-meta", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getOrCreateInitializersFor = getOrCreateInitializersFor;
  exports.getInitializersFor = getInitializersFor;
  var initializersMap = new WeakMap();

  function getOrCreateInitializersFor(target) {
    if (!initializersMap.has(target)) {
      var parentInitializers = getInitializersFor(Object.getPrototypeOf(target));
      initializersMap.set(target, Object.create(parentInitializers || null));
    }

    return initializersMap.get(target);
  }

  function getInitializersFor(target) {
    // Reached the root of the prototype chain
    if (target === null) return target;

    return initializersMap.get(target) || getInitializersFor(Object.getPrototypeOf(target));
  }
});